import { Text } from "@fluentui/react";
import { useQueryClient } from "@tanstack/react-query";
import Modal from "components/Modal";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Api from "./api";
import { useApi } from "./apiContext";
import { useAuthState } from "./authContext";
import { LocalStorageKeys } from "./constants";
import AuftragStatusDTO, {
  AuftragsArt,
  AuftragStatus,
} from "./dto/AuftragStatusDTO";
import { useMessageActions } from "./messageActionsContext";
import User, { Action as UserAction } from "./models/User";
import { queries } from "./queries";
import { Feature, isActive } from "./utils/featureFlags";

const TRANSITION_TIMEOUT_MS = 3000;
const POLLING_INTERVAL_MS = 2000;
const POLLING_MAX_RETRY = 30;
const LOCAL_STORAGE_KEY = LocalStorageKeys.OrderLastUpdate;

type Action =
  | { type: "FETCH_INIT" }
  | {
      type: "FETCH_SUCCESS";
      payload: AuftragStatusDTO;
      updatedMs?: string | null;
    }
  | { type: "FETCH_FAILURE" }
  | { type: "RETRY_CANCEL" }
  | { type: "RESET" };
type State = {
  isLoading: boolean;
  isError: boolean;
  data?: AuftragStatusDTO;
  isSuccess: boolean;
  updatedMs?: string | null;
  isRetryCanceled: boolean;
};
type OrderStateProviderProps = { children: React.ReactNode };

const OrderStateContext = React.createContext<
  (State & { update: (data?: AuftragStatusDTO) => void }) | undefined
>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        isRetryCanceled: false,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isRetryCanceled: false,
        isError:
          state.data?.status === AuftragStatus.IN_BEARBEITUNG &&
          action.payload.status === AuftragStatus.AUFTRAG &&
          !!action.payload.verarbeitung?.fehlerMeldung,
        isSuccess:
          state.data?.status === AuftragStatus.IN_BEARBEITUNG &&
          action.payload.status === AuftragStatus.KEIN_AUFTRAG,
        data: action.payload,
        updatedMs: action.updatedMs,
      };
    case "FETCH_FAILURE":
      return { ...state, isLoading: false, isError: true, isSuccess: false };
    case "RETRY_CANCEL":
      return { ...state, isRetryCanceled: true };
    case "RESET":
      return { ...initialState };
  }
}

const initialState: State = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isRetryCanceled: false,
};

function OrderStateProvider({ children }: OrderStateProviderProps) {
  const { t } = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const api = useApi();
  const messageActions = useMessageActions();
  const auth = useAuthState();
  const retryCounter = React.useRef(0);
  const previousProfile = React.useRef<User | null | undefined>();
  const queryClient = useQueryClient();

  const fetchStatus = useCallback(
    async (updatedMs?: string | null) => {
      const response = await api.getAuftragStatus();

      if (response.type === "Success") {
        retryCounter.current = 0;

        if (response.data?.status === AuftragStatus.IN_BEARBEITUNG) {
          setTimeout(fetchStatus, POLLING_INTERVAL_MS);
        }

        if (updatedMs) {
          queryClient.invalidateQueries({
            queryKey: queries.orders.index.queryKey,
          });
        }

        dispatch({ type: "FETCH_SUCCESS", payload: response.data, updatedMs });
      } else if (retryCounter.current < POLLING_MAX_RETRY) {
        retryCounter.current += 1;
        setTimeout(fetchStatus, POLLING_INTERVAL_MS);
      } else {
        dispatch({ type: "RETRY_CANCEL" });
      }
    },
    [api, queryClient]
  );

  const update = useCallback(
    async (data?: AuftragStatusDTO) => {
      const updatedMs = new Date().getMilliseconds().toString();
      localStorage.setItem(LOCAL_STORAGE_KEY, updatedMs);
      queryClient.invalidateQueries({
        queryKey: queries.orders.index.queryKey,
      });

      if (data) {
        dispatch({ type: "FETCH_SUCCESS", payload: data, updatedMs });
      } else {
        fetchStatus();
      }
    },
    [fetchStatus, queryClient]
  );

  React.useEffect(() => {
    if (previousProfile.current && !auth.profile) {
      dispatch({ type: "RESET" });
    }

    previousProfile.current = auth.profile;
  }, [auth]);

  React.useEffect(() => {
    let didCancel = false;

    async function fetchData(api: Api) {
      dispatch({ type: "FETCH_INIT" });

      const response = await api.getAuftragStatus();

      if (didCancel) {
        return;
      }

      if (response.type === "Success") {
        if (response.data?.status === AuftragStatus.IN_BEARBEITUNG) {
          setTimeout(fetchStatus, POLLING_INTERVAL_MS);
        }

        dispatch({ type: "FETCH_SUCCESS", payload: response.data });
      }
    }

    if (
      !api ||
      !auth.profile ||
      !auth.profile.allowedActions.includes(UserAction.VIEW_ORDERS)
    ) {
      return;
    }

    fetchData(api);

    return () => {
      didCancel = true;
    };
  }, [api, auth, fetchStatus]);

  React.useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key === LOCAL_STORAGE_KEY) {
        const { newValue } = event;
        fetchStatus(newValue);
      }
    };

    window.addEventListener("storage", listener);

    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [fetchStatus]);

  React.useEffect(() => {
    if (state.isError) {
      messageActions.showMessage({
        type: "error",
        text:
          state.data?.verarbeitung?.fehlerMeldung ?? t("myOrder.submit.error"),
      });
    }
  }, [
    messageActions,
    state.isError,
    state.data?.verarbeitung?.fehlerMeldung,
    t,
  ]);

  React.useEffect(() => {
    if (state.isSuccess) {
      messageActions.showMessage({
        type: "success",
        text: t("myOrder.submit.success"),
      });
    }
  }, [messageActions, state.isSuccess, t]);

  React.useEffect(() => {
    if (state.isSuccess) {
      setTimeout(fetchStatus, TRANSITION_TIMEOUT_MS);
    }
  }, [state.isSuccess, fetchStatus]);

  return (
    <OrderStateContext.Provider value={{ ...state, update }}>
      {children}
      {state.isRetryCanceled && (
        <Modal isVisible title={t("errors.error")}>
          <Text block>{t("errors.serviceUnavailable")}</Text>
        </Modal>
      )}
    </OrderStateContext.Provider>
  );
}

function useOrderState() {
  const context = React.useContext(OrderStateContext);

  if (context === undefined) {
    throw new Error("useOrderState must be used within a OrderStateProvider");
  }

  return context;
}

function useMatchingHeldOrders(vDate: string, orderType: AuftragsArt) {
  const orderState = useOrderState();
  const heldOrders = useMemo(() => {
    const orders = orderState.data?.heldOrders ?? [];

    return orders.filter(
      (order) =>
        !!order.pubSpec &&
        order.pubSpec.vdatum === vDate &&
        order.auftragsArt === orderType
    );
  }, [orderState.data?.heldOrders, orderType, vDate]);

  return heldOrders;
}

function useHeldOrderArticleIds(disableHeldOrderItems?: boolean) {
  const orderState = useOrderState();
  const heldOrderArticleIds = useMemo(() => {
    if (!(disableHeldOrderItems && isActive(Feature.AUFTRAG_ZURUECKSTELLEN))) {
      return [];
    }
    return (
      orderState.data?.heldOrders.flatMap(
        (order) => order.affectedArticleIds
      ) ?? []
    );
  }, [disableHeldOrderItems, orderState.data?.heldOrders]);

  return heldOrderArticleIds;
}

export {
  OrderStateProvider,
  useHeldOrderArticleIds,
  useMatchingHeldOrders,
  useOrderState,
};
