import { mergeStyleSets, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import Api from "./api";
import CardForm from "./components/CardForm";
import { LocalStorageKeys } from "./constants";
import User from "./models/User";
import { queries } from "./queries";

type Action =
  | { type: "FETCH_INIT" }
  | { type: "FETCH_SUCCESS"; payload: User | null }
  | { type: "FETCH_FAILURE" }
  | { type: "EXTERNAL_UPDATE"; payload: string }
  | { type: "UPDATE"; payload: User };
type Dispatch = (action: Action) => void;
type State = {
  isLoading: boolean;
  isError: boolean;
  lastAuthentication: string;
  profile?: User | null;
};
type AuthProviderProps = { children: React.ReactNode };

const AuthStateContext = React.createContext<State | undefined>(undefined);
const AuthDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const localStorageAuthKey = LocalStorageKeys.AuthUser;
const NOT_AUTHENTICATED = "";

function storageValue(user: User | null) {
  return user ? `${user.kundennr}#${user.benutzerkennung}` : NOT_AUTHENTICATED;
}

function parseStorageValue(val: string): {
  benutzerkennung: string;
  kundennr?: string;
} {
  const idx = val.indexOf("#");
  if (idx < 0) {
    // bis Sprint 96 war im storage value nur die Benutzerkennnung enthalten
    return { benutzerkennung: val };
  }
  return {
    kundennr: val.substring(0, idx),
    benutzerkennung: val.substring(idx + 1, val.length),
  };
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, isLoading: true, isError: false };
    case "FETCH_SUCCESS":
      localStorage.setItem(localStorageAuthKey, storageValue(action.payload));
      return {
        ...state,
        isLoading: false,
        isError: false,
        lastAuthentication: storageValue(action.payload),
        profile: action.payload,
      };
    case "FETCH_FAILURE":
      return { ...state, isLoading: false, isError: true };
    case "EXTERNAL_UPDATE":
      return {
        ...state,
        lastAuthentication: action.payload,
        isLoading: false,
        isError: false,
      };
    case "UPDATE":
      return { ...state, profile: { ...state.profile, ...action.payload } };
    // default:
    // throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const initialState: State = {
  isLoading: false,
  isError: false,
  lastAuthentication: NOT_AUTHENTICATED,
};

function getClassNames() {
  return mergeStyleSets({
    centered: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      dispatch({ type: "FETCH_INIT" });
      try {
        const api = Api.getInstance({
          resetAuth: () => {
            dispatch({ type: "FETCH_SUCCESS", payload: null });
            queryClient.invalidateQueries({
              queryKey: queries.orders.index.queryKey,
            });
          },
        });
        const response = await api.getProfile();

        if (didCancel) {
          return;
        }

        if (response.type === "Success") {
          dispatch({ type: "FETCH_SUCCESS", payload: response.data });
        } else if (
          response.type === "UnauthorizedFailure" ||
          response.type === "ForbiddenFailure"
        ) {
          dispatch({ type: "FETCH_SUCCESS", payload: null });
        } else {
          dispatch({ type: "FETCH_SUCCESS", payload: null });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: "FETCH_FAILURE" });
        }
      }
    }

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [queryClient]);

  React.useEffect(() => {
    const listener = (ev: StorageEvent) => {
      if (ev.key === localStorageAuthKey) {
        const { newValue } = ev;
        dispatch({
          type: "EXTERNAL_UPDATE",
          payload: newValue || NOT_AUTHENTICATED,
        });
      }
    };
    window.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [state]);

  const { t } = useTranslation();

  const styles = getClassNames();

  if (state.isLoading || state.profile === undefined) {
    return (
      <div className={styles.centered}>
        <Spinner size={SpinnerSize.large} />
      </div>
    );
  }

  const { lastAuthentication } = state;
  const knownAuthentication = storageValue(state.profile);
  if (lastAuthentication !== knownAuthentication) {
    return (
      <div className={styles.centered}>
        <CardForm
          heading={t("authChanged.title")}
          submitLabel={t("authChanged.reload")}
          onSubmit={(e: FormEvent) => {
            // Dies ist die Holzhammermethode, Redirect des Browsers auf die Basis-URL (z.B. https://portal.ifaffm.de).
            // Nicht sehr elegant, garantiert aber dafür, dass wirklich alles neu initialisiert wird.
            e.preventDefault();
            setTimeout(() => {
              // Warum geht das nicht direkt sondern nur in timeout?
              window.location.assign(window.location.origin);
            }, 20);
          }}
          disabled={false}
        >
          <Text>
            {state.lastAuthentication
              ? t("authChanged.newUser", {
                  kundennr: "???",
                  ...parseStorageValue(state.lastAuthentication),
                })
              : t("authChanged.logout")}
          </Text>
        </CardForm>
      </div>
    );
  }

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuthDispatch, useAuthState };
